#site-header {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  .poweredby {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 1rem;
  }

  .externalyticslogo {
    //filter: contrast(0.5);

    @include media-breakpoint-up(md) {
      max-width: 160px;
      margin-left: 0.6rem;
    }
    @include media-breakpoint-down(md) {
      max-width: 35%;
      margin-left: 0.6rem;
    }
  }

  small {
    font-size: $font-small;
  }

  .icslogo {
    max-width: 270px;
    height: auto;
    //@extend .d-none;
    @extend .d-md-block;
    @include media-breakpoint-down(lg) {
      padding-top: 10px;
    }
  }
  .icsico {
    max-width: 55px;
    height: auto;
    @extend .d-block;
    @extend .d-md-none;
  }
  .poweredbylogo {
    max-width: 86px;
    height: auto;
  }
  .userico {
    max-width: 14px;
    height: auto;
    fill: $primary-light;
  }
  .enellogo {
    max-width: 110px;
    height: auto;
    &.desktop-logo {
      @include media-breakpoint-down(md) {
        visibility: hidden;
        height: 0px;
        width: 0px;
      }
    }
  }

  .navbar-nav {
    @media (min-width: 600px) {
      flex-direction: row;
      align-items: center !important;
    }
  }

  .navbar {
    @extend .align-items-center;
    //padding-top: 10px;
    //padding-bottom: 10px;
    padding: 20px 2rem;
    @include media-breakpoint-down(md) {
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    @media (min-width: 2236px) {
      max-width: 97%;
      margin: 0 auto;
      padding-top: 2rem;
    }
  }

  .left-side,
  .right-side {
    @extend .align-items-center;
    @include media-breakpoint-down(md) {
      //-ms-flex-align: start !important;
      //align-items: flex-start !important;
    }
  }

  .left-side {
    .navbar-brand {
      margin-right: 20px;
      padding-left: 10px;

      @include media-breakpoint-down(md) {
        margin-right: 0px;
        padding-left: 0px;
        margin-top: -1px;
      }
    }
  }

  .right-side {
    &.navbar-nav {
      @include media-breakpoint-down(md) {
        flex-direction: row !important;
      }
    }

    .nav-link,
    .form-inline,
    //.user.dropdown,
    .enellogo,
    .about.dropdown {
      margin-left: 12px;
    }

    .enellogo {
      margin-right: 0px;
      height: 2em;
    }

    .form-inline {
      .form-control {
        border: 0px;
      }
      &.select-currency {
        width: 90px;
      }
      &.select-lang {
        width: 85px;
      }
      &.select-lang,
      &.select-currency,
      &.select-about {
        @include media-breakpoint-down(md) {
          width: 100%;
          > div {
            width: 90%;
          }
        }
        .custom-select__dropdown-indicator {
          svg {
            fill: $primary-light;
          }
        }
        .custom-select__dropdown-indicator {
          padding-left: 0px;
        }
        .custom-select__control {
          @include media-breakpoint-down(md) {
            justify-content: space-between;
          }
        }
      }
      &.select-about {
        .custom-select__value-container {
          width: 100px;
          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }

        .custom-select__dropdown-indicator {
          width: 68%;
          @include media-breakpoint-down(md) {
            width: 100%;
          }
        }
      }
    }
    .dropdown-menu {
      .dropdown-item {
        a {
          color: $primary-light;
        }
      }
    }
  }

  .powered-by-header {
    text-align: inherit;
    //min-width: 15rem;
    min-width: -webkit-fill-available;

    @media (min-width: 600px) {
      text-align: left;
      margin-right: 2rem;
    }
  }

  .search-bar {
    width: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    transition: width 1s;

    @include media-breakpoint-down(md) {
      padding-top: 5px;
      margin-left: 5px !important;
      margin-right: 5px !important;
    }

    .search-box {
      width: 80%;
    }

    .search-btn {
      width: 20px;
      padding-left: 0px;
      padding-right: 0px;
    }

    &:hover {
      width: 200px;
      transition: width 1s;
      @include media-breakpoint-down(lg) {
        width: 100px;
      }
      @include media-breakpoint-down(md) {
        width: 200px;
      }
      @include media-breakpoint-down(sm) {
        width: 100px;
      }
    }
  }

  .dropdown-table {
    #tablet-toggle {
      &.dropdown-toggle {
        padding-top: 0px;
        padding-left: 2px;
        padding-right: 5;
        .enellogo {
          @include media-breakpoint-down(sm) {
            max-width: 50px;
            border: 1px solid #ccc;
            padding: 16px 2px;
            border-radius: 100px;
            margin-left: 0px;
          }
        }
      }
    }
    .dropdown-menu {
      position: absolute;
      right: 0px;
      left: auto;

      .dropdown-item {
        padding-right: 0.7rem !important;
        padding-left: 0 !important;
        color: $primary-light;
        padding: inherit 0.7rem inherit 0 !important;
      }
      .form-inline {
        .form-control {
          padding-left: 0px;
        }
      }
      .form-group {
        width: 100%;
        .form-control {
          width: 100%;
        }
      }
      .dropdown.nav-item {
        //&.user,
        &.about {
          margin-left: 0px;
          margin-right: 0px;
          .nav-link {
            &.dropdown-toggle::after {
              right: 26px;
              position: absolute;
              top: 15px;
            }
          }
          svg {
            margin-left: 5px;
          }
        }
        &.about {
          .nav-link {
            margin-left: 20px;
          }
        }
        /* .dropdown-menu {
          position: static;
          border: 0px;
          padding-left: 0px;
          padding-right: 0px;
          .dropdown-item {
            padding-left: 15px;
            padding-right: 0px;
          }
        } */
      }
    }
  }

  .navbar-light {
    .navbar-nav {
      .nav-link {
        color: $primary-light;
      }
    }
  }

  .sub-navbar {
    @extend .d-none;
    @extend .d-lg-block;
    font-size: $font-base;
    border-top: 1px solid $primary-lightest-saturate;
    padding-bottom: 0px;
    padding-top: 5px;
    .navbar-nav {
      .nav-item {
        text-transform: uppercase;
        padding-top: 8px;
        a {
          border-bottom: 2px solid $light-primary;
          padding-bottom: 18px;
          &:hover {
            border-bottom: 2px solid $third-color;
            color: $third-color;
          }
          &.active {
            border-bottom: 2px solid $third-color;
            font-weight: $font-w-bold;
            color: $third-color;
          }
        }
        .nav-link {
          padding-right: 0rem;
          padding-left: 0rem;
          margin-right: 0.5rem;
          margin-left: 0.5rem;
        }
      }
    }
  }
}

.dropdown-toggle::after {
  border-top: 0em;
  border-right: 0em;
  border-bottom: 0;
  border-left: 0.3em;
  border: solid $second-color;
  border-width: 0 1px 1px 0;
  padding: 2px;
  display: inline-block;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 10px;
}

.menu-container {
  li {
    .menu-link {
      @extend .d-flex;
      cursor: pointer;
      font-size: $font-big;
      font-weight: $font-w-bold;
      color: $primary;
      @extend .px-3;
      @extend .py-2;
      .nav-ico {
        svg {
          width: 37px;
          fill: $primary !important;
          .ico {
            fill: $primary !important;
          }
          .esagono {
            display: none;
          }
        }
        @extend .mr-2;
      }
      &.active,
      &:hover {
        text-decoration: none;
        background: $third-color;
      }
    }
  }
}

.menu-container {
  @extend .p-0;
  @extend .mb-0;
  li {
    list-style: none;
    .menu-link {
      @extend .d-flex;
      cursor: pointer;
      font-size: $font-big;
      font-weight: $font-w-bold;
      color: $primary;
      @extend .px-3;
      @extend .py-2;
      .nav-ico {
        svg {
          width: 37px;
          fill: $primary !important;
          .ico {
            fill: $primary !important;
          }
          .esagono {
            display: none;
          }
        }
        @extend .mr-3;
      }
      &:hover {
        text-decoration: none;
        background: $third-color;
      }
    }
    &.menu-item {
      .sub-accordion {
        .card {
          .card-header {
            .btn {
              @extend .px-1;
              .nav-ico {
                svg {
                  width: 37px;
                  @extend .mr-0;
                  fill: $primary !important;
                  .ico {
                    fill: $primary !important;
                  }
                  .esagono {
                    display: none;
                  }
                }
                @extend .mr-3;
              }
              .arrow-ico {
                @extend .ml-auto;
                svg {
                  width: 10px;
                }
              }
              @extend .d-flex;
              &.panel-toggle {
                font-size: $font-big;
                font-weight: $font-w-bold;
                color: $primary;
                text-transform: none;
              }
              &:hover,
              &:focus {
                text-decoration: none;
              }
            }
          }
          .card-body {
            .menu-container {
              margin-left: -14px;
              margin-right: -14px;
              li {
                .menu-link {
                  font-weight: $font-w-medium;
                }
              }
            }
          }
          &.active-panel {
            border-left: 3px solid $light-primary;
            .card-header {
              .btn {
                .arrow-ico {
                  @extend .ml-auto;
                  svg {
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }
          &:last-child {
            border-bottom: 0px;
          }
        }
      }
    }
  }
}

#sidebar-mobile {
  @extend .d-block;
  @extend .d-lg-none;
  .btn-open-menu {
    width: 35px;
    height: 65px;
    position: absolute;
    left: 15px;
    @include media-breakpoint-down(sm) {
      height: 75px;
    }
  }
  .btn-close-menu {
    width: 35px !important;
    height: 65px !important;
    left: 15px;
    top: 0px !important;
    @include media-breakpoint-down(sm) {
      height: 50px !important;
    }
  }
  .left-side-menu {
    background-color: $light-primary;
    padding-top: 75px;
    .icslogo {
      max-width: 270px;
      height: auto;
    }
    .home-item {
      position: absolute;
      top: 22px;
      left: 66px;
      @include media-breakpoint-down(sm) {
        top: 14px;
      }
    }
  }
  .accordion {
    .card {
      border: 0px;
      border-radius: 0px;
      box-shadow: none;
      @extend .my-0;
      @extend .px-2;
      border-left: 3px solid $light-primary;
      border-bottom: 1px solid $primary-lighter;
      .card-header {
        border-bottom: 0px;
        background-color: transparent;
        @extend .px-0;
        .btn {
          text-align: left;
          @extend .px-0;
          &.panel-toggle {
            text-decoration: none;
            color: $primary-light;
            text-transform: uppercase;
            font-weight: $font-w-medium;
          }
          &:focus,
          &.focus {
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
          }
        }
      }
      .card-body {
        visibility: hidden;
        @extend .px-0;
        @extend .pt-0;
        .menu-container {
          .menu-link {
            @extend .align-items-center;
          }
        }
      }
      &.active-panel {
        border-left: 3px solid $third-color;
        .card-header {
          .btn {
            &.panel-toggle {
              color: $third-color;
              font-weight: $font-w-bold;
            }
          }
        }
        .card-body {
          visibility: visible;
          .menu-container {
            li {
              &.menu-item {
                .sub-accordion {
                  .card {
                    border-radius: 0px;
                    .card-header {
                      .btn {
                        @extend .px-1;
                        @extend .align-items-center;
                        &.panel-toggle {
                          font-size: $font-big;
                          font-weight: $font-w-bold;
                          color: $primary;
                          text-transform: none;
                        }
                      }
                    }
                    &.active-panel {
                      border-left: 3px solid $light-primary;
                    }
                    &:last-child {
                      border-bottom: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.desktop-menu {
  padding-top: 3px;
  .panel-menu {
    width: 150px;
    transition: width 1s;
    background-color: $light-primary;
    .nav-text {
      @extend .d-none;
      a {
        text-decoration: none;
        color: inherit;
      }
    }
    .header-nav {
      padding-right: 3rem;
      @extend .py-2;
      @extend .d-flex;
      @extend .justify-content-end;
      .max-minimize {
        width: 10px;
        height: auto;
      }
    }
    &.expanded {
      width: 300px;
      transition: width 1s;
      overflow-x: hidden;
      .nav-text {
        @extend .d-block;
        white-space: nowrap;
        a {
          text-decoration: none;
          color: inherit;
        }
      }
      .header-nav {
        padding-right: 4.7rem;
        .max-minimize {
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
        }
      }
    }
    .menu-container {
      li {
        &.menu-item {
          .menu-link {
            @extend .px-5;
            @extend .align-items-center;
          }
          .sub-accordion {
            .card {
              @extend .my-0;
              @extend .p-0;
              box-shadow: 0px 0px 0px;
              border: 0px;
              &.active-panel {
                border-left: 0px;
              }
              .card-header {
                @extend .p-0;
                background-color: transparent;
                border-bottom: 0px;
                .btn {
                  border-radius: 0px;
                  @extend .px-5;
                  @extend .align-items-center;
                  &:focus {
                    box-shadow: 0 0 0 0rem;
                  }
                }
              }
              .card-body {
                .menu-container {
                  @extend .mx-0;
                  .menu-item {
                    .menu-link {
                      font-size: $font-base;
                      .nav-ico {
                        width: 37px;
                        text-align: center;
                        svg {
                          width: 27px;
                          fill: $primary !important;
                          .ico {
                            fill: $primary !important;
                          }
                          .esagono {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.dropdown-menu {
  padding: 0.5rem 0.8rem !important;
  min-width: max-content;
}

.user-nav-dropdown .dropdown-menu.show {
  right: 0 !important;
  left: auto !important;
  max-width: max-content;

  .dropdown-item {
    padding: 0.4rem 0;
  }
}

.dropdown-icon {
  width: 1.4rem;
  height: 1.4rem;
  margin-left: 0.7rem;
  fill: $primary-light;
}

#checkPreSubmit {
  transform: scale(1.3);
  accent-color: $second-color;
}

.check-text {
  padding-left: 0.7rem;
  padding-top: 0.1rem;
  font-size: initial;
  color: grey;
  margin-bottom: 0;
}

.a-check-text {
   color: $second-color;

   &:hover {
      color: $second-color;
   }
}

.links-nav {
   border-bottom: 1px solid $second-color;
   margin: 0.5rem;
   padding: 2px !important;
}