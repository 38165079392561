#progetti {
  background: $primary-lightest;
  .cards-maps {
    background-color: $light-primary;
    background: url('../img/map.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .number-row {
    @include media-breakpoint-down(sm) {
      .card {
        font-size: $font-base;
      }
    }
    @include media-breakpoint-up(md) {
      .card {
        font-size: $font-big;
      }
    }
  }
}

div.projectstab {
  .scenario-link {
    color: $second-color;
  }
}

span.scenario-details {
  font-size: 1.1rem;
  text-transform: none;
  color: #666;
  font-weight: normal;
  padding: 0 1em;
  border-right: 1px solid #666;
  &.no-pipe {
    border-right: none;
  }
}
.bluicon {
  color: #08a2aa;
}

.w150 {
  min-width: 150px;
}

/* .projects-list-table {
  .ag-header-cell {
    color: #1C2743;
    font-weight: bold;
    border-bottom: 1px solid #C2C923;
  }
  .ag-header-cell-label {
    padding: 0.6rem 0;
  }
  .ag-cell {
    padding: 0.6rem 0.1rem;
  }
} */

.ag-theme-alpine {
  font-size: 1.1rem !important;
  font-family: inherit !important;

  .ag-header {
    background-color: inherit !important;
    border: 0 !important;
  }
  .ag-header-cell {
    color: #1c2743;
    font-weight: bold;
    border-bottom: 1px solid #c2c923;
  }
  .ag-header-cell-label {
    padding: 0.6rem 0;
  }
  .ag-cell {
    padding: 0.6rem 0.1rem;
  }
  .ag-row {
    font-size: 1.1rem !important;
  }
  .ag-root-wrapper {
    border: 0 !important;
  }

  @include media-breakpoint-down(sm) {
    .ag-paging-panel {
      font-size: 0.8rem !important;
      margin-bottom: 1rem;
    }
  }
}

#grid-wrapper {
  width: 100%;
  height: 100%;
}

.projects-list-table {
  width: 100%;
  height: 100%;

  .ag-body-horizontal-scroll {
    // display: none;
    // width: 7px;
  }

  // .ag-body-horizontal-scroll,
  // .ag-body-horizontal-scroll-viewport,
  // .ag-body-horizontal-scroll-container,
  // .ag-horizontal-right-spacer {
  //   max-height: 12px;
  //   height: 12px;
  // }

  .ag-horizontal-right-spacer::-webkit-scrollbar {
    display: none;
  }
}

#page-size {
  border: none;
  text-align: center;

  &:focus-visible {
    outline: none;
  }
}

.font-lg-2 {
  font-size: $font-lg-2;
}

.min-label-width {
  min-width: max-content;
}

.lineheight-initial {
  line-height: initial;
}

.sroi-details-padding {
  padding: 0;

  @media (min-width: 1367px) {
    padding-left: 1.5rem;
  }
  @media screen and (max-width: 1600px) {
    margin-top: 2% !important;
    margin-bottom: 2% !important;
  }
}

.avg-tab-head {
  max-width: 100px;
  font-weight: bold;
  font-size: 1rem;
}
